// routes
import { 
  PATH_DASHBOARD,
  PATH_USER,
  PATH_LOAN,
  PATH_REPAYMENT,
  PATH_STAFF,
  PATH_CUSTOMER,
  PATH_SETTING
 } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const subMenuIcon = (ic) => <Iconify icon={ic} />;

const navConfig = [
  {
    subheader: '',
    items: [

      // USER
      // ----------------------------------------------------------------------
      {
        title: 'user',
        path: PATH_USER.root,
        icon: subMenuIcon("bi:person-fill"),
        children: [
          { title: 'Google Authenticator', path: PATH_USER.googleAuth, icon: subMenuIcon("ant-design:google-outlined") },
          { title: 'Duty Scheduler', path: PATH_USER.dutyScheduler, icon: subMenuIcon("uil:schedule") },
          { title: 'Manage Admin', path: PATH_USER.manageAdmin, icon: subMenuIcon("fa-solid:user-cog") },
          { title: 'Manage Group', path: PATH_USER.manageGroup, icon: subMenuIcon("fa-solid:user-cog") },
          { title: 'Admin Amendment', path: PATH_USER.adminAmendment, icon: subMenuIcon("fa-solid:user-edit") },
          { title: 'Activity Log', path: PATH_USER.activityLog, icon: subMenuIcon("fa-solid:user-clock") },
          { title: 'Inactive Log', path: PATH_USER.inactiveLog, icon: subMenuIcon("fa-solid:user-times"), info: <Label color="error">6</Label> },
        ]
      },

      // LOAN
      // ----------------------------------------------------------------------
      {
        title: 'loan',
        path: PATH_LOAN.root,
        icon: subMenuIcon("mdi:bank"),
        children: [
          { title: 'Active Loans', path: PATH_LOAN.active, icon: subMenuIcon("clarity:bank-solid") },
          { title: 'Loan Listing', path: PATH_LOAN.listing, icon: subMenuIcon("clarity:bank-solid-badged"), info: <Label color="error">105</Label>, },
          { title: 'Overdue Loan Listing', path: PATH_LOAN.overdueListing, icon: subMenuIcon("clarity:bank-solid-alerted") },
          { title: 'Bad Pay Loans', path: PATH_LOAN.badPay, icon: subMenuIcon("fluent:text-bullet-list-square-warning-20-regular") },
          { title: 'Non-Performing Loan', path: PATH_LOAN.nonPerforming, icon: subMenuIcon("ic:baseline-money-off") },
        ]
      },

      // REPAYMENT
      // ----------------------------------------------------------------------
      {
        title: 'repayment',
        path: PATH_REPAYMENT.root,
        icon: subMenuIcon("ri:file-transfer-fill"),
        children: [
          { title: 'Repayment Log', path: PATH_REPAYMENT.log, icon: subMenuIcon("carbon:connection-receive") },
          { title: 'Repayment Log Audit', path: PATH_REPAYMENT.logAudit, icon: subMenuIcon("material-symbols:payments-outline-rounded") },
          { title: 'Manual Banking', path: PATH_REPAYMENT.manualBanking, icon: subMenuIcon("fa-solid:piggy-bank") },
        ]
      },

      // STAFF
      // ----------------------------------------------------------------------
      {
        title: 'staff',
        path: PATH_STAFF.root,
        icon: subMenuIcon("bi:person-lines-fill"),
        children: [
          { title: 'Staff Comm(Overdue)', path: PATH_STAFF.commission, icon: subMenuIcon("fa-solid:user-tag") },
          { title: 'Staff Reward(Admin)', path: PATH_STAFF.reward, icon: subMenuIcon("fluent:reward-24-filled") },
        ]
      },

      // CUSTOMER
      // ----------------------------------------------------------------------
      {
        title: 'customer',
        path: PATH_CUSTOMER.root,
        icon: subMenuIcon("fa6-solid:rectangle-list"),
        children: [
          { title: 'Member List', path: PATH_CUSTOMER.list, icon: subMenuIcon("fluent:apps-list-24-regular") },
          { title: 'KYC Verify List', path: PATH_CUSTOMER.kycVerifyList, icon: subMenuIcon("fa6-solid:clipboard-user") },
          { title: 'Blacklisted Customer', path: PATH_CUSTOMER.blacklisted, icon: subMenuIcon("fa-solid:user-alt-slash") },
          { title: 'Comm Wthdrwl Listing', path: PATH_CUSTOMER.commissionWithdrawalListing, icon: subMenuIcon("eva:arrow-circle-up-fill") },
        ]
      },

      // SETTING
      // ----------------------------------------------------------------------
      {
        title: 'setting',
        path: PATH_SETTING.root,
        icon: subMenuIcon("ant-design:setting-filled"),
        children: [
          { title: 'Mobile Apps Setting', path: PATH_SETTING.mobileApps, icon: subMenuIcon("tabler:device-mobile") },
          { title: 'Anncmnt/Ntfctn List', path: PATH_SETTING.announcementNotifList, icon: subMenuIcon("ic:round-edit-notifications") },
          { title: 'Export History', path: PATH_SETTING.exportHistory, icon: subMenuIcon("tabler:file-export") },
          { title: 'Blacklisted Mobile', path: PATH_SETTING.blacklistedMobile, icon: subMenuIcon("tabler:device-mobile-off") },
          { title: 'Hardware', path: '#', icon: subMenuIcon("maki:hardware"), dialogBox: 'hardware' },
          { title: 'Call', path: '#', icon: subMenuIcon("bx:phone-call"), dialogBox: 'call' },
        ]
      },
    ]
  },

  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //     { title: 'ecommerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.new },
  //         { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
  //         { title: 'account', path: PATH_DASHBOARD.user.account },
  //       ],
  //     },

  //     // E-COMMERCE
  //     {
  //       title: 'ecommerce',
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.cart,
  //       children: [
  //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //         { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
  //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //         { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
  //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
  //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //       ],
  //     },

  //     // INVOICE
  //     {
  //       title: 'invoice',
  //       path: PATH_DASHBOARD.invoice.root,
  //       icon: ICONS.invoice,
  //       children: [
  //         { title: 'list', path: PATH_DASHBOARD.invoice.list },
  //         { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.invoice.new },
  //         { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
  //       ],
  //     },

  //     // BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.blog.new },
  //       ],
  //     },
  //   ],
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">+32</Label>,
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
  //   ],
  // },

  // DEMO MENU STATES
  // {
  //   subheader: 'Other cases',
  //   items: [
  //     {
  //       // default roles : All roles can see this entry.
  //       // roles: ['user'] Only users can see this item.
  //       // roles: ['admin'] Only admin can see this item.
  //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
  //       // Reference from 'src/guards/RoleBasedGuard'.
  //       title: 'item_by_roles',
  //       path: PATH_DASHBOARD.permissionDenied,
  //       icon: ICONS.menuItem,
  //       roles: ['admin'],
  //       caption: 'only_admin_can_see_this_item',
  //     },
  //     {
  //       title: 'menu_level_1',
  //       path: '#1',
  //       icon: ICONS.menuItem,
  //       children: [
  //         { title: 'menu_level_2', path: '#2', disabled: true },
  //         {
  //           title: 'menu_level_2',
  //           path: '#3',
  //           children: [
  //             { title: 'menu_level_3', path: '#4' },
  //             { title: 'menu_level_3', path: '#5' },
  //           ],
  //         },
  //       ],
  //     },
  //     { title: 'item_disabled', path: '#disabled', icon: ICONS.menuItem, disabled: true },
  //     {
  //       title: 'item_label',
  //       path: '#label',
  //       icon: ICONS.menuItem,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },
  //     { title: 'item_caption', path: '#caption', icon: ICONS.menuItem, caption: 'description' },
  //   ],
  // },
];

export default navConfig;
