// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  featured_lottery: `Featured Lottery`,
  try_your_luck_today: `Try your luck today`,
  play: `Play`,
  twenty_four_hours: `24hours`,
  open_bet: `Open Bet`,
  rounds: `Rounds`,
  join_now: `Join Now!`,
  closing_at: `Closing at`,
  not_accepting_bets: `Not accepting bets!`,
  rules: `Rules`,
  rules_title: `Lucky draw lottery`,
  rules_subtitle: `Lucky draw lottery, Rules for betting  Yi Ki`,
  rules_bullet1: `Open for online betting 88 rounds per day, prizes are issued every 15 minutes.`,
  rules_bullet2: `The first round starts at 6:00 a.m. (Start guessing new results from 5:00 a.m.)`,
  rules_bullet3: `Closing for the final round at 3:45 a.m.`,
  rules_bullet4: `Numbers can be sent for free unlimited number of times with clear and verifiable rules.`,
  rules_bullet5: `The member who shoots the 16th number will receive a free credit of 400 credit and the number 1 free of 200 credit, but must have a minimum bet of 100 credit for that round.`,
  rules_top_running_numbers: `Top running numbers, guess the top 3 results like a government lottery`,
  rules_if_number_of_members: `If the number of members participating in the prediction
    <strong>is less than 20 ranks</strong>, in any case The system will Refund and balance who thrust into that round immediately`,
  rules_if_system_crashes: `!!If the system crashes or crashes During the period before the closure of participation in sending guessing prize numbers until causing members to be unable to access the system, the team will judge the prize draw as appropriate.`,
  rules_judging: `(Judging is at the discretion of the team. The team will judge with the most transparency and fairness)`,
  rules_before_participation: `Before the participation time expires, it results in predicting the winning numbers at 1 minute, the system has a problem and cannot be accessed. causing you to be unable to participate in guessing the numbers In this case, the team will cancel and refund the credit in that round immediately. Although before that there were more than 20 members shooting in the order.`,
  rules_issuance_of_prize: `(The issuance of prizes is calculated from the prize results that every member submits a 5-digit number [12345],  then the team will bring together all the numbers sent by members in that round and take the sum that has been brought. to subtract with the 16th number of that round and the results obtained will be drawn as prizes)`,
  rules_example_of_issuance: `Example of issuing prizes The sum of all number shots is  1234567890 – [51876  example number  16] = 1234516014  << result after subtracting number  16.`,
  rules_the_total_of: `The total of the last 3 digits will be drawn as the top 3 numbers of the Yi Ki lottery round, then the sum of thousands and tens of thousands will be drawn as the bottom 2 numbers of the Yi Ki lottery round.`,
  rules_according_to: `According to the example, the sum after subtracting the 16th order =  12345[16][014]  = The top 3 winning numbers are =  [014]  The bottom 2 winning numbers are =  [16]  The member who can shoot the 16th number. Get free credit 400 baht`,
  rules_special: `special!!  The member who shoots the 1st number will receive a free credit of 200 credit.`,
  rules_condition: `(Conditions must have a minimum bet for that round of 100 credit or more)`,
  rules_condition1: `Lottery Yi Ki is open to bet 88 rounds per day.`,
  rules_condition2: `Give out prizes every 15 minutes.`,
  rules_condition3: `Open the first round 06:00 – 03:45 after the Yi Ki lottery closes for each round.`,
  rules_condition4: `The number shooting system opens for firing 2 minutes after the number shooting system closes for another 1 minute.`,
  rules_condition5: `The system will check the statement, which takes 2-5 minutes to pay.`,
  example: `Example`,
  rules_yiki_round_closing: `Yi Ki Round 1 closes at 06:00, shoots numbers up to 06:02, and the system will check the post at 06:03 and pay all of you within no more than 06: 08am`,
  rules_please_set_time: `** Please set the time of your playback device to match the web time. So that you won't be confused by the closing time. The time to display the results of sending numbers will take from the actual server **`,
  rules_payment_rate: `Yi Ki lottery payment rate`,
  rules_3_top: `3 upper digits, paid price 850 credit`,
  rules_3_toads: `3 Tods, price 120 credit`,
  rules_2_top_bottom: `2 top-bottom, price 92 credit`,
  rules_running_numbers: `The top 3 running numbers are 3.2 credit.`,
  rules_running_numbers_bottom_2: `Running numbers, 2 lower digits, price 4.2 credit`,
  rules_price_change: `*If the price has changed Will notify you 7 days in advance of the change.`,
  rules_if_want_cancel: `* If you want to cancel the bet This can be done until 1 minute before the closing of the bet.`,
  shoot_numbers: `Shoot Numbers`,
  round: `Round`, // รอบที่
  pull_a_note: `Pull a note`,
  put_a_price: `Put a price`,
  stab_list: `Stab List`,
  dial_numbers: `Dial Numbers by yourself`,
  choose_from_panel: `Choose from panel`,
  top_3: `Top 3`,
  toads_3: `3 Toads`,
  top_2: `Top 2`,
  bottom_2: `Bottom 2`,
  back_3: `Back 3`,
  back_2: `Back 2`,
  run_on: `Run On`,
  run_down: `Run Down`,
  selected_items: `Selected item(s)`,
  specify_numbers: `Specify Numbers`,
  cancel_latest: `Cancel Latest`,
  clear_data: `Clear Data`,
  betting_conditions: `Betting Conditions`,
  minimum_bet_per_time: `Minimum bet per time: `,
  maximum_bet_per_time: `Maximum bet per time: `,
  maximum_bet_per_number: `Maximum bet per number: `,
  three: `Three`,
  two: `Two`,
  run_number: `Run Number`,
  find_numbers: `find numbers`,
  reverse_numbers: `Reverse Numbers`,
  pay: `Pay: `,
  more_options: `more options`,
  goals_19: `19 GOALS`,
  double_number: `DOUBLE NUMBER`,
  front_swipe: `front swipe`,
  back_swipe: `back swipe`,
  two_low: `two low`,
  two_tall: `two tall`,
  two_odds: `two odd`,
  two_even: `two even`,
  lottery_list: `Lottery List`,
  random_numbers: `Random Numbers`,
  add_numbers: `Add Numbers`,
  enter_5_digits: `Enter 5 digits`,
  final_closing_number: `Closing accepting prize prediction numbers`,
  sum_shot_numbers: `Sum shot numbers`,
  place_16th: `16th place`,
  member_shoot_number: `Members can shoot numbers.`,
  place: `place`,
  list_of_guessers: `List of guessers`,
  look_at_duplicates: `Look at repeating numbers`,
  multiply_rate: `Multiply Rate`,
  win_rate: `Win Amount`,
  remove_duplicates: `Cut Repeating numbers`,
  all_same_prices: `All "same" prices`,
  credit_balance: `Credit Balance`,
  total_bet: `Total Bet`,
  cancel_all: `Cancel All`,
  post: `Bet Now`,
  you_have: `You have`,
  unread_notification: `unread notification(s)`,
  not_enough_balance: `Not enough balance`,
  no_bet_found: `No bet(s) found`,
  place_bet_success: `Place bet success!`,


  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default en;
