import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isOpenCall: false,
  isOpenHardware: false,
  creditBalance: 0,
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // OPEN CALL
    openCall(state) {
      console.log('enter call');
      state.isOpenCall = true;
    },

    // CLOSE CALL
    closeCall(state) {
      console.log('enter call close');
      state.isOpenCall = false;
    },

    // OPEN HARDWARE
    openHardware(state) {
      state.isOpenHardware = true;
    },

    // CLOSE HARDWARE
    closeHardware(state) {
      state.isOpenHardware = false;
    },

    // UPDATE CREDIT BALANCE
    updateCreditBalance(state, action) {
      state.creditBalance = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openCall, closeCall, openHardware, closeHardware, updateCreditBalance } = slice.actions;
