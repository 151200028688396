// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  featured_lottery: `หวยเด่น`,
  try_your_luck_today: `ลองเสี่ยงโชคของคุณวันนี้`,
  play: `เล่น`,
  twenty_four_hours: `24 ชั่วโมง`,
  open_bet: `เปิดเดิมพัน`,
  rounds: `รอบ`,
  join_now: `เข้าร่วมเดี๋ยวนี้!`,
  closing_at: `ปิดที่`,
  not_accepting_bets: `ไม่รับเดิมพัน!`,
  rules: `กติกา`,
  rules_title: `หวยจับยี่กี`,
  rules_subtitle: `หวยจับยี่กี่ กติกาการเเทง Yi Ki`,
  rules_bullet1: `เปิดรับเเทงออนไลน์วันละ 88 รอบ ออกผลรางวัลทุกๆ 15 นาที`,
  rules_bullet2: `รอบเเรกเริ่มเวลา 06:00น. (เริ่มทายผลใหม่ได้ตั้งเเต่ 05:00น.)`,
  rules_bullet3: `ปิดรับเเทงรอบสุดท้าย เวลา 03:45น.`,
  rules_bullet4: `สามารถส่งตัวเลขได้ฟรีไม่จำกัดจำนวนครั้งด้วยกติกาที่ชัดเสจเเละตรวจสอบได้`,
  rules_bullet5: `สมาชิกที่ยิงเลขได้ ลำดับที่ 16 จะได้รับเครดิตฟรี 400 เครดิต เเละ ลำดับ 1 ฟรี 200 เครดิต เเต่จะต้องมียอดเดิมพันรอบนั้นขั้นต่ำ 100 เครดิตขั้นไป`,
  rules_top_running_numbers: `เลขวิ่งบน รับทายผล 3 ตัวบนเหมือนหวยรัฐบาล`,
  rules_if_number_of_members: `หากเลขที่สมาชิกร่วมทายผล มีไม่ถึง 20 ลำดับ ไม่ว่ากรณีใดก็ตาม ทางระบบจะทำการคืนโพยเเละยอดเงินที่เเทงเข้ามาในรอบนั้นทันที`,
  rules_if_system_crashes: ` !!หากระบบล่มหรือขัดข้อง ระหว่างก่อนปิดการร่วมส่งทายผลตัวเลขออกรางวัลจนเป็นสาเหตุทำให้สมาชิกไม่สามารถเเข้าใช้งานระบบได้ทีมงานจะทำการตัดสินการออกรางวัลตามความเหมาะสม`,
  rules_judging: `(การตัดสินขึ้นอยู่กับดุลพินิจของทีมงาน ทีมงานจะตัดสินด้วยความโปร่งใสเเละเป็นธรรมที่สุด)`,
  rules_before_participation: `ก่อนหมดเวลาร่วมส่งผลทายผลตัวเลขออกรางวัลที่ 1 นาทีระบบเกิดปัญหาข้อข้องไม่สามารถเข้าใช้งานได้ ทำให้ท่านไม่สามารถเข้าร่วมทายผลตัวเลขได้ กรณีนี้ทางทีมงานจะทำการยกเลิกเเละคืนเงินเครดิตในรอบนั้นทีนที ถึงเเม้ว่าก่อนหน้านั้นจะมีสมาชิยิงเข้ามามากกว่า 20 ลำดับก็ตาม`,
  rules_issuance_of_prize: `(การออกผลรางวัล คิดจาก ผลรางวัลทีสมาชิกทุกๆท่านส่งตัวเลขจำนวน 5 หลัก [12345] จากนั้นทีมงานจะนำตัวเลขที่สมาชิกส่งเข้ามาทั้งหมดในรอบนั้นๆมารวมกัน เเล้ว เอาผลรวมที่ได้นำไปลบกับเลข ลำดับที่ 16 ของรอบนั้น เเละจะนำผลที่ได้มาเป็ยผลออกรางวัล)`,
  rules_example_of_issuance: `ตัวอย่าง การออกผลรางวัล ผลรวมการยิงเลขทั้งหมดได้ 1234567890 – [51876 ตัวอย่างลำดับที่ 16] = 1234516014 << ผลหลังจากกลบลำดับที่ 16`,
  rules_the_total_of: `ผลรวม 3 ตัวท้าย จะถูกนำมาออกผลรางวัลเป็นเลข 3 ตัวบนของหวยยี่กี่รอบนั้นผลรวมหลักพันเเละหลักหมื่นจะถูกนำมาออกผลรางวัลเป็นเลข 2 ตัวล่าง ของหวยยี่กีรอบนั้น`,
  rules_according_to: `ตามตัวอย่างผลรวมหลังลบลำดับที่ 16 ได้ = 12345[16][014] = เลขที่ออก 3 ตัวบนคือ = [014] เลขที่ออก 2 ตัวล่างคือ = [16] สมาชิกที่ยิงเลขได้ลำดับที่ 16 ได้รับเครดิตฟรี 400 เครดิต`,
  rules_special: `พิเศษ!! สมาชิกที่ยิงเลขได้ลำดับที่ 1 จะได้รับเครดิตฟรี 200 เครดิต`,
  rules_condition: `(เงื่อนไขจะต้องมียอดเดิมพันรอบนั้นขั้นต่ำ 100 เครดิต ขึ้นไป)`,
  rules_condition1: `หวยยี่กีเปิดรับเเทงวันละ 88 รอบ`,
  rules_condition2: `ออกผลรางวัลทุกๆ 15 นาที`,
  rules_condition3: `เปิดรอบเเรก 06:00น. – 03:45น หลังจากหวยยี่กีปิดรับเเทงเเต่ละรอบ`,
  rules_condition4: `ระบบยิงเลขเปิดให้ยิง 2 นาทีหลังจากระบบยิงเลขปิดอีก 1 นาที`,
  rules_condition5: `ระบบจะทำการตรวจโพยโดยใช้เวลาการจ่ายเงิน 2 – 5 นาที`,
  example: `ตัวอย่าง`,
  rules_yiki_round_closing: `ยี่กี่รอบที่ 1 ปิดรับเเทง 06:00น. ยิงเลขได้ถึง 06:02น. เเละ ระบบจะตรวจสอบโพยเวลา 06:03น. เเละจ่ายเงินท่านทั้งหมดเสร็จภายในเวลาไม่เกิน 06:08น`,
  rules_please_set_time: `** โปรดตั้งเวลาของอุปกรณ์เข้าเล่นของท่านให้ตรงกับเวลาในเว็บ เพื่อที่ท่านจะได้ไม่สับสนกับเวลาปิดรับ เวลาที่เเสดงผลการส่งตัวเลขจะใช้เวลาจากเซิฟเวอร์จริง **`,
  rules_payment_rate: `อัตตราการจ่ายหวยยี่กี`,
  rules_3_top: `3ตัวบนราคาจ่าย850บ`,
  rules_3_toads: `คางคก 3 ตัว ราคา 120 เครดิต`,
  rules_2_top_bottom: `2ตัวบน-ล่าง ราคา 92 บ`,
  rules_running_numbers: `เลขวิ่ง 3 ตัวบน 3.2 หลัก`,
  rules_running_numbers_bottom_2: `เลขวิ่ง2ตัวล่างราคา4.2เครดิต`,
  rules_price_change: `*หากราคามีการเปลี่ยนเเปลง จะเเจ้งให้ทราบล่วงหน้าก่อนมีการเปลี่ยนเเปลง 7 วัน`,
  rules_if_want_cancel: `*หากต้องการยกเลิกการแทง สามารถทำได้จนกว่าก่อนการปิดรับแทง 1 นาที`,
  shoot_numbers: `ยิงเลข`,
  round: `รอบที่`,
  pull_a_note: `ดึงโพย`,
  put_a_price: `ใส่ราคา`,
  stab_list: `รายการแทง`,
  dial_numbers: `กดเลขเอง`,
  choose_from_panel: `เลือกจากแผง`,
  top_3: `3ตัวบน`,
  toads_3: `3ตัวโต๊ด`,
  top_2: `2ตัวบน`,
  bottom_2: `2ตัวล่าง`,
  back_3: `3ตัวกลับ`,
  back_2: `2ตัวกลับ`,
  run_on: `วิ่งบน`,
  run_down: `วิ่งล่าง`,
  selected_items: `รายการที่เลือก`,
  specify_numbers: `ระบุตัวเลข`,
  cancel_latest: `ยกเลิกล่าสุด`,
  clear_data: `ล้างข้อมูล`,
  betting_conditions: `เงื่อนไขการแทง`,
  minimum_bet_per_time: `แทงขั้นต่ำต่อครั้ง: `,
  maximum_bet_per_time: `แทงสูงสุดต่อครั้ง: `,
  maximum_bet_per_number: `แทงสูงสุดต่อเลข: `,
  three: `สามตัว`,
  two: `สองตัว`,
  run_number: `เลขวิ่ง`,
  find_numbers: `ค้นหาตัวเลข`,
  reverse_numbers: `กลับตัวเลข`,
  pay: `จ่าย: `,
  more_options: `ตัวเลือกเพิ่มเติม`,
  goals_19: `19 ประตู`,
  double_number: `เลขเบิ้ล`,
  front_swipe: `รูดหน้า`,
  back_swipe: `รูดหลัง`,
  two_low: `สองตัวต่ำ`,
  two_tall: `สองตัวสูง`,
  two_odds: `สองตัวคี่`,
  two_even: `สองตัวคุ่`,
  lottery_list: `รายการลอตเตอรี`,
  random_numbers: `ตัวเลขสุ่ม`,
  add_numbers: `เพิ่มหมายเลข`,
  enter_5_digits: `ใส่ตัวเลข 5 หลัก`,
  final_closing_number: `- ปิดรับการทายผลตัวเลขออกรางวัล -`,
  sum_shot_numbers: `ผลรวมยิงเลข`,
  place_16th: `เลขแถวที่ 16`,
  member_shoot_number: `สมาชิกยิงเลขได้`,
  place: `อันดับ`,
  list_of_guessers: `รายชื่อผู้ทายเลข`,
  look_at_duplicates: `ดูเลขซ้ำ`,
  multiply_rate: `อัตราคูณ`,
  win_rate: `เรทชนะ`,
  remove_duplicates: `ตัดเลขซ้ำ`,
  all_same_prices: `ราคา"เท่ากัน"ทั้งหมด`,
  credit_balance: `ยอดเครดิตคงเหลือ`,
  total_bet: `รวมยอดแทง`,
  cancel_all: `ยกเลิกทั้งหมด`,
  post: `ส่งโพย`,
  you_have: `You have`,
  unread_notification: `unread notification(s)`,
  not_enough_balance: `Not enough balance`,
  no_bet_found: `No bet found`,
  place_bet_success: `Place bet success!`,

  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default en;
