// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ar = {
  demo: {
    title: `Arabic`,
    introduction: `لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة. لقد نجت ليس فقط خمسة قرون ، ولكن أيضًا القفزة في التنضيد الإلكتروني ، وظلت دون تغيير جوهري. تم نشره في الستينيات من القرن الماضي بإصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.`,
  },
  docs: {
    hi: `أهلا`,
    description: `تحتاج مساعدة؟ \n الرجاء مراجعة مستنداتنا`,
    documentation: `توثيق`,
  },
  featured_lottery: `အထူးအသားပေးထီ`,
  try_your_luck_today: `ဒီနေ့ မင်းကံကောင်းအောင်ကြိုးစားပါ။`,
  play: `ကစားပါ။`,
  twenty_four_hours: `24 နာရီ`,
  open_bet: `Bet ကိုဖွင့်ပါ။`,
  rounds: `ဟိုနေ့က`,
  join_now: `ယခု ပါဝင်လိုက်ပါ။`,
  closing_at: `ပိတ်ရန်_မှာ`,
  not_accepting_bets: `လောင်းကစားလက်မခံပါ။`,
  rules: `กติกา`,
  rules_title: `หวยจับยี่กี`,
  rules_subtitle: `หวยจับยี่กี่ กติกาการเเทง Yi Ki`,
  rules_bullet1: `เปิดรับเเทงออนไลน์วันละ 88 รอบ ออกผลรางวัลทุกๆ 15 นาที`,
  rules_bullet2: `รอบเเรกเริ่มเวลา 06:00น. (เริ่มทายผลใหม่ได้ตั้งเเต่ 05:00น.)`,
  rules_bullet3: `ปิดรับเเทงรอบสุดท้าย เวลา 03:45น.`,
  rules_bullet4: `สามารถส่งตัวเลขได้ฟรีไม่จำกัดจำนวนครั้งด้วยกติกาที่ชัดเสจเเละตรวจสอบได้`,
  rules_bullet5: `สมาชิกที่ยิงเลขได้ ลำดับที่ 16 จะได้รับเครดิตฟรี 400 เครดิต เเละ ลำดับ 1 ฟรี 200 เครดิต เเต่จะต้องมียอดเดิมพันรอบนั้นขั้นต่ำ 100 เครดิตขั้นไป`,
  rules_top_running_numbers: `เลขวิ่งบน รับทายผล 3 ตัวบนเหมือนหวยรัฐบาล`,
  rules_if_number_of_members: `หากเลขที่สมาชิกร่วมทายผล มีไม่ถึง 20 ลำดับ ไม่ว่ากรณีใดก็ตาม ทางระบบจะทำการคืนโพยเเละยอดเงินที่เเทงเข้ามาในรอบนั้นทันที`,
  rules_if_system_crashes: ` !!หากระบบล่มหรือขัดข้อง ระหว่างก่อนปิดการร่วมส่งทายผลตัวเลขออกรางวัลจนเป็นสาเหตุทำให้สมาชิกไม่สามารถเเข้าใช้งานระบบได้ทีมงานจะทำการตัดสินการออกรางวัลตามความเหมาะสม`,
  rules_judging: `(การตัดสินขึ้นอยู่กับดุลพินิจของทีมงาน ทีมงานจะตัดสินด้วยความโปร่งใสเเละเป็นธรรมที่สุด)`,
  rules_before_participation: `ก่อนหมดเวลาร่วมส่งผลทายผลตัวเลขออกรางวัลที่ 1 นาทีระบบเกิดปัญหาข้อข้องไม่สามารถเข้าใช้งานได้ ทำให้ท่านไม่สามารถเข้าร่วมทายผลตัวเลขได้ กรณีนี้ทางทีมงานจะทำการยกเลิกเเละคืนเงินเครดิตในรอบนั้นทีนที ถึงเเม้ว่าก่อนหน้านั้นจะมีสมาชิยิงเข้ามามากกว่า 20 ลำดับก็ตาม`,
  rules_issuance_of_prize: `(การออกผลรางวัล คิดจาก ผลรางวัลทีสมาชิกทุกๆท่านส่งตัวเลขจำนวน 5 หลัก [12345] จากนั้นทีมงานจะนำตัวเลขที่สมาชิกส่งเข้ามาทั้งหมดในรอบนั้นๆมารวมกัน เเล้ว เอาผลรวมที่ได้นำไปลบกับเลข ลำดับที่ 16 ของรอบนั้น เเละจะนำผลที่ได้มาเป็ยผลออกรางวัล)`,
  rules_example_of_issuance: `ตัวอย่าง การออกผลรางวัล ผลรวมการยิงเลขทั้งหมดได้ 1234567890 – [51876 ตัวอย่างลำดับที่ 16] = 1234516014 << ผลหลังจากกลบลำดับที่ 16`,
  rules_the_total_of: `ผลรวม 3 ตัวท้าย จะถูกนำมาออกผลรางวัลเป็นเลข 3 ตัวบนของหวยยี่กี่รอบนั้นผลรวมหลักพันเเละหลักหมื่นจะถูกนำมาออกผลรางวัลเป็นเลข 2 ตัวล่าง ของหวยยี่กีรอบนั้น`,
  rules_according_to: `ตามตัวอย่างผลรวมหลังลบลำดับที่ 16 ได้ = 12345[16][014] = เลขที่ออก 3 ตัวบนคือ = [014] เลขที่ออก 2 ตัวล่างคือ = [16] สมาชิกที่ยิงเลขได้ลำดับที่ 16 ได้รับเครดิตฟรี 400 เครดิต`,
  rules_special: `พิเศษ!! สมาชิกที่ยิงเลขได้ลำดับที่ 1 จะได้รับเครดิตฟรี 200 เครดิต`,
  rules_condition: `(เงื่อนไขจะต้องมียอดเดิมพันรอบนั้นขั้นต่ำ 100 เครดิต ขึ้นไป)`,
  rules_condition1: `หวยยี่กีเปิดรับเเทงวันละ 88 รอบ`,
  rules_condition2: `ออกผลรางวัลทุกๆ 15 นาที`,
  rules_condition3: `เปิดรอบเเรก 06:00น. – 03:45น หลังจากหวยยี่กีปิดรับเเทงเเต่ละรอบ`,
  rules_condition4: `ระบบยิงเลขเปิดให้ยิง 2 นาทีหลังจากระบบยิงเลขปิดอีก 1 นาที`,
  rules_condition5: `ระบบจะทำการตรวจโพยโดยใช้เวลาการจ่ายเงิน 2 – 5 นาที`,
  example: `ตัวอย่าง`,
  rules_yiki_round_closing: `ยี่กี่รอบที่ 1 ปิดรับเเทง 06:00น. ยิงเลขได้ถึง 06:02น. เเละ ระบบจะตรวจสอบโพยเวลา 06:03น. เเละจ่ายเงินท่านทั้งหมดเสร็จภายในเวลาไม่เกิน 06:08น`,
  rules_please_set_time: `** โปรดตั้งเวลาของอุปกรณ์เข้าเล่นของท่านให้ตรงกับเวลาในเว็บ เพื่อที่ท่านจะได้ไม่สับสนกับเวลาปิดรับ เวลาที่เเสดงผลการส่งตัวเลขจะใช้เวลาจากเซิฟเวอร์จริง **`,
  rules_payment_rate: `อัตตราการจ่ายหวยยี่กี`,
  rules_3_top: `3ตัวบนราคาจ่าย850บ`,
  rules_3_toads: `คางคก 3 ตัว ราคา 120 เครดิต`,
  rules_2_top_bottom: `2ตัวบน-ล่าง ราคา 92 บ`,
  rules_running_numbers: `เลขวิ่ง 3 ตัวบน 3.2 หลัก`,
  rules_running_numbers_bottom_2: `เลขวิ่ง2ตัวล่างราคา4.2เครดิต`,
  rules_price_change: `*หากราคามีการเปลี่ยนเเปลง จะเเจ้งให้ทราบล่วงหน้าก่อนมีการเปลี่ยนเเปลง 7 วัน`,
  rules_if_want_cancel: `*หากต้องการยกเลิกการแทง สามารถทำได้จนกว่าก่อนการปิดรับแทง 1 นาที`,
  shoot_numbers: `Shoot Numbers`,
  round: `Round`, // รอบที่
  pull_a_note: `Pull a note`,
  put_a_price: `Put a price`,
  stab_list: `Stab List`,
  dial_numbers: `Dial Numbers by yourself`,
  choose_from_panel: `Choose from panel`,
  top_3: `Top 3`,
  toads_3: `3 Toads`,
  top_2: `Top 2`,
  bottom_2: `Bottom 2`,
  back_3: `Back 3`,
  back_2: `Back 2`,
  run_on: `Run On`,
  run_down: `Run Down`,
  selected_items: `Selected item(s)`,
  specify_numbers: `Specify Numbers`,
  cancel_latest: `Cancel Latest`,
  clear_data: `Clear Data`,
  betting_conditions: `Betting Conditions`,
  minimum_bet_per_time: `Minimum bet per time: `,
  maximum_bet_per_time: `Maximum bet per time: `,
  maximum_bet_per_number: `Maximum bet per number: `,
  three: `Three`,
  two: `Two`,
  run_number: `Run Number`,
  find_numbers: `find numbers`,
  reverse_numbers: `Reverse Numbers`,
  pay: `Pay: `,
  more_options: `more options`,
  goals_19: `19 GOALS`,
  double_number: `DOUBLE NUMBER`,
  front_swipe: `front swipe`,
  back_swipe: `back swipe`,
  two_low: `two low`,
  two_tall: `two tall`,
  two_odds: `two odd`,
  two_even: `two even`,
  lottery_list: `Lottery List`,
  random_numbers: `Random Numbers`,
  add_numbers: `Add Numbers`,
  enter_5_digits: `Enter 5 digits`,
  final_closing_number: `Closing acceptin prize prediction numbers`,
  sum_shot_numbers: `Sum shot numbers`,
  place_16th: `16th place`,
  member_shoot_number: `Members can shoot numbers.`,
  place: `place`,
  list_of_guessers: `List of guessers`,
  look_at_duplicates: `Look at repeating numbers`,
  multiply_rate: `Multiply Rate`,
  win_rate: `Win Amount`,
  remove_duplicates: `Cut Repeating numbers`,
  all_same_prices: `All "same" prices`,
  credit_balance: `Credit Balance`,
  total_bet: `Total Bet`,
  cancel_all: `Cancel All`,
  post: `Post`,
  you_have: `You have`,
  unread_notification: `unread notification(s)`,
  not_enough_balance: `Not enough balance`,
  no_bet_found: `No bet found`,
  place_bet_success: `Place bet success!`,

  app: `تطبيق`,
  user: `المستعمل`,
  list: `قائمة`,
  edit: `تعديل`,
  shop: `متجر`,
  blog: `مقالات`,
  mail: `بريد`,
  chat: `دردشة`,
  cards: `البطاقات`,
  posts: `المشاركات`,
  create: `يزيد`,
  kanban: `كانبان`,
  general: `جنرال لواء`,
  banking: `الخدمات المصرفية`,
  booking: `الحجز`,
  profile: `الملف الشخصي`,
  account: `الحساب`,
  product: `المنتوج`,
  invoice: `فاتورة`,
  details: `تفاصيل`,
  checkout: `الدفع`,
  calendar: `التقويم`,
  analytics: `التحليلات`,
  ecommerce: `التجارة الإلكترونية`,
  management: `إدارة`,
  menu_level_1: `مستوى القائمة 1`,
  menu_level_2: `مستوى القائمة 2`,
  menu_level_3: `مستوى القائمة 3`,
  item_disabled: `العنصر معطل`,
  item_label: `تسمية العنصر`,
  item_caption: `عنوان العنصر`,
  description: `وصف`,
  other_cases: `حالات اخرى`,
  item_by_roles: `عنصر حسب الأدوار`,
  only_admin_can_see_this_item: `يمكن للمسؤول فقط رؤية هذا العنصر`,
};

export default ar;
